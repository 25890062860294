import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React from "react";
import Author from "../components/author";
import Card from "../components/card";
import Container from "../components/container";
import Header from "../components/header";

export default function Index({ location, data: { uses, site, blog, author } }) {
  return (
    <Container>
      <HelmetDatoCms seo={blog.seo} favicon={site.favicon} />
      <Header currentPath={location.pathname} />
      <Author author={{ name: author.name, picture: author.picture.gatsbyImageData, summary: author.summary?.value }} />
      <h2 className="mb-8 text-3xl sm:text-4xl font-bold tracking-tighter leading-tight">
        Tech & Tools I use 💻 🛠️
      </h2>
      <div className="flex flex-col align-center">
        {uses.nodes.map((use) => (
          <Card
            title={use.title}
            description={
              JSON.parse(use.description)
            }
            image={use.image?.gatsbyImageData}
          />
        ))}
      </div>
    </Container>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    blog: datoCmsBlog {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    uses: allDatoCmsUse(sort: {order: ASC, fields: meta___createdAt}){
      nodes {
        title
        description
        image {
          gatsbyImageData
          alt
        }
      }
    }
    author: datoCmsAuthor{
      name
       picture {
        gatsbyImageData(
          layout: FIXED
          width: 200
          height: 200
        )
      }
      summary{
        value
      }
    }
  }
`;
