import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

export default function Card({ title, description, image }) {
  return (
    <div class="card rounded-lg my-6 flex-1 px-12">
      <h4 className="text-xl sm:text-3xl font-bold mb-8 underline decoration-dotted underline-offset-8">
        {title}
      </h4>
      <ul className="list-[square] text-sm sm:text-xl">
        {Object.keys(description).map((key, index) => (
          <li index={index} className="leading-loose">
            <span className="font-bold">{key}</span>
            <span>{description[key] ? `: ${description[key]}` : ""}</span>
          </li>
        ))}
      </ul>
      {image && (
        <div className="flex justify-center">
          <GatsbyImage
            image={image}
            alt={image.alt}
            className="w-full max-w-unset sm:max-w-[80%] h-auto"
            objectFit="contain"
          />
        </div>
      )}
    </div>
  );
}
